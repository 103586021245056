<template>
    <Items />
</template>

<script>

import Items from './DashboardItems.vue';

export default {
    data() {
        return {
            loading: false,
            error: null
        }
    },
    components: {
        Items
    },
    name: 'Dashboard',
}
</script>
