<template>
    <div>
        <div v-if="loading">
            Loading...
        </div>
        <div v-if="error" class="error">
            {{ error }}
        </div>
        <v-row>
            <v-col sm="6">
                <v-select
                    v-model="categoryValues"
                    :items="category"
                    attach
                    chips
                    label="Category"
                    multiple
                    @change="handleFilterChange"
                ></v-select>
            </v-col>
            <v-col sm="6">
                <v-select
                    v-model="sizesValues"
                    :items="sizes"
                    attach
                    chips
                    label="Sizes"
                    multiple
                    @change="handleFilterChange"
                ></v-select>
            </v-col>
        </v-row>
        
        
        <v-row no-gutters>
        <v-col
          v-for="item in items"
          :key="item.id"
          cols="12"
          sm="12"
          md="6"
          xl="4"
          class="my-2"
        >
            <ItemCard :item="item"/>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from '../components/ItemCard.vue';

export default {
    data () {
        return {
            dialog: null,
            loading: false,
            error: null,
            sizes: [
                'OS', 'XS', 'S', 'M', 'L', 'XL', '4', '4', '4.5', '5', '5,5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '14'
            ],
            sizesValues: [],
            category: ['SNEAKERS', 'CLOTHES', 'OTHER'],
            categoryValues: []
        }
    },
    computed: {
        ...mapGetters({
            items: 'items'
        })
    },
    created () {
        this.loading = true;
        this.$store.dispatch("fetchItems", {});
        this.loading = false;
    },
    methods: {
        handleFilterChange() {
            this.$store.dispatch("fetchItems", { size: this.sizesValues, category: this.categoryValues });
        }
    },
    components: {
        ItemCard
    }
}
</script>