<template>
    <div>
        <div v-if="loading">
            Loading...
        </div>
        <div v-if="error" class="error">
            {{ error }}
        </div>
        <v-container fluid fill-height>
            <v-card width="100vw" class="pa-4">
                <v-toolbar flat>
                    <v-toolbar-title>Items</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-dialog
                    v-model="dialog"
                    width="500"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >
                        Add Item
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                            {{ formTitle }}
                        </v-card-title>
                        <v-card-text>
                        <form @submit="handleSubmit">
                            <v-text-field label="Title" required v-model="addItem.title"></v-text-field>
                            <v-text-field label="Description" required v-model="addItem.description"></v-text-field>
                            <v-text-field prefix="$" label="Price" required v-model="addItem.price"></v-text-field>
                            <v-select
                            :items="sizes"
                            label="Size"
                            v-model="addItem.size"
                            required
                            ></v-select>
                            <v-select
                            :items="categories"
                            label="Category"
                            v-model="addItem.category"
                            required
                            ></v-select>
                            <v-row justify="space-around">
                                <v-checkbox label="Sold" v-model="addItem.sold"></v-checkbox>
                                <v-checkbox label="Visible" v-model="addItem.visible"></v-checkbox>
                            </v-row>
                            <v-file-input @change="previewImage" label="File input" filled prepend-icon="mdi-camera" v-model="addItem.pendingFile"></v-file-input>
                            <v-row align="center" justify="center" class="mb-3">
                                <v-img max-height="150" max-width="100" :src="addItem.imageURL"></v-img>
                            </v-row>
                            <v-row align="center" justify="center" class="mt-2">
                                <v-btn type="submit">{{ formTitle }}</v-btn>
                            </v-row>
                        </form>
                        </v-card-text>
                    </v-card>
                    </v-dialog>
                </v-toolbar>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :custom-filter="filter"
                    >
                    <template v-slot:top>
                        <v-text-field
                            v-model="search"
                            label="Search"
                            class="mx-4"
                        ></v-text-field>                
                    </template>
                    <template v-slot:[`item.image`]="{ item }">
                        <v-img class="ma-1" contain max-height="40" max-width="40" :lazy-src="`${item.imageURL}`" :src="`${item.imageURL}`"></v-img>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="handleEditItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item.id)"
                    >
                        mdi-delete
                    </v-icon>
                    </template>
                    </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

const defaultItem = {
    id: -1,
    title: '',
    description: '',
    price: 10,
    size: '10',
    imageURL: '',
    pendingFile: null,
    sold: false,
    visible: false,
    category: 'SNEAKERS'
}

export default {
    data () {
        return {
            dialog: null,
            search: '',
            loading: false,
            error: null,
            headers: [
                { text: 'Title', value: 'title' },
                { text: 'Description', value: 'description' },
                { text: 'Size', value: 'size' },
                { text: 'Price', value: 'price' },
                { text: 'Image', value: 'image', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            sizes: [
                'OS', 'XS', 'S', 'M', 'L', 'XL', '4', '4', '4.5', '5', '5,5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '14'
            ],
            categories: ['SNEAKERS', 'CLOTHES', 'OTHER'],
            addItem: defaultItem
        }
    },
    computed: {
        ...mapGetters({
            items: 'adminItems'
        }),
        formTitle () {
            return this.addItem.id === -1 ? 'New Item' : 'Edit Item'
        },
    },
    created () {
        this.loading = true;
        this.$store.dispatch("fetchItemsAdmin", {});
        this.loading = false;
    },
    methods: {
        filter (value, search) {
            return value != null && search != null && typeof value === 'string' && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        },
        deleteItem(id) {
            this.$store.dispatch("deleteItem", {id});
        },
        handleSubmit(e){
            e.preventDefault();
            this.loading = true;
            const payload = { title: this.addItem.title, description: this.addItem.description, size: this.addItem.size, price: this.addItem.price, sold: this.addItem.sold, visible: this.addItem.visible, category: this.addItem.category };

            if (this.addItem.pendingFile) {
                payload.file = this.addItem.pendingFile;
            }

            if (this.addItem.id !== -1) {
                payload.id = this.addItem.id;
            }

            if (this.addItem.id !== -1) {
                this.$store.dispatch("updateItem", payload)
            } else {
                this.$store.dispatch("addItem", payload)
            }
            this.loading = false;
            this.dialog = false;
        },
        handleEditItem(item){
            this.addItem = item;

            this.dialog = true
        },
        previewImage(){
            if (this.addItem.pendingFile) {
                this.addItem.imageURL = URL.createObjectURL(this.addItem.pendingFile);
            } else {
                this.addItem.imageURL = '';
            }
        }
    },
    watch: {
        dialog: function(newValue) {
            if (!newValue) {
                this.addItem = defaultItem
            }
        }
    }
}
</script>