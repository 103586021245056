<template>
    <div>
        <div v-if="loading">
            Loading...
        </div>
        <div v-if="error" class="error">
            {{ error }}
        </div>
        <div v-if="item">
            <v-row>
                <v-col md="6" xs="12">
                    <h1>{{ item.title }}</h1>
                    <div class="text-subtitle-3">
                        Description: {{item.description}}
                    </div>
                    <div class="text-subtitle-3">
                        Category: {{item.category}}
                    </div>
                    <div class="text-subtitle-3">
                        Size: {{item.size}}
                    </div>
                    <div class="text-subtitle-3" v-if="item.sold">
                        Price: <strike> $ {{item.price}} CAD </strike> <v-icon color="green darken-2">mdi-tag-off</v-icon>
                    </div>
                    <div class="text-subtitle-3" v-else>
                        Price: $ {{item.price}} CAD <v-icon color="green darken-2">mdi-tag</v-icon>
                    </div>
                </v-col>
                <v-col md="6" xs="12">
                    <v-img contain max-height="500" max-width="500" :lazy-src="`${item.imageURL}`" :src="`${item.imageURL}`"></v-img>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            error: null
        }
    },
    computed: {
        item () {
            return this.$store.getters.currentItem
        }
    },
    created () {
        this.loading = true;
        const { id } = this.$route.params;
        this.$store.dispatch("fetchItem", { id });
        this.loading = false;
    },
    name: 'Items'
}
</script>