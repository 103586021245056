<template>
<v-container fill-height fluid>
  <v-row align="center" justify="center">
      <v-card class="pa-8 rounded-lg" min-width="400px">
            <form @submit="handleSubmit">
                <v-text-field label="Code" required type="text" v-model="code"></v-text-field>
                <v-text-field label="Email" required v-model="email"></v-text-field>
                <v-text-field label="Password" required type="password" v-model="password"></v-text-field>
                <v-text-field label="Confirm Password" required type="password" v-model="password2"></v-text-field>
                <v-row align="center" justify="center" class="mt-2">
                    <v-btn type="submit">Register</v-btn>
                </v-row>
            </form>
        </v-card>
  </v-row>
</v-container>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            password2: '',
            code: '',
            loading: false,
        }
    },
    methods: {
        async handleSubmit(e) {
            e.preventDefault();

            if (this.password !== this.password2) {
                this.$store.dispatch("setError", { error: "Passwords do not match" })
                return
            }

            this.loading = true;
            try {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: this.email, password: this.password, code: this.code }),
                    credentials: 'include'
                };

                const res = await fetch(`${process.env.VUE_APP_API_URL}/api/auth/register`, requestOptions);
                if (res.status === 200) {
                    this.$router.push('/dashboard');
                } else {
                    const j = await res.json();
                    this.$store.dispatch("setError", { error: j.error })
                }
            } catch (e) {
                this.$store.dispatch("setError", { error: "Error" })
            }
            this.loading = false;
        }
    }
}
</script>